<template>
  <div>
    <PageHeader>
      <div class="text-gray-900 text-2xl font-semibold">Tableau de bord</div>
    </PageHeader>

    <div class="flex flex-col p-6">
      <div class="flex justify-between">
        <h4 class="font-medium mb-3">LES INDICATEURS DU MOIS EN COURS</h4>
      </div>
      <div class="flex flex-row">
        <div class="flex flex-col w-2/3">
          <div class="flex items-center justify-start">
            <div
              style="color: #22075e; background-color: #d3adf7"
              class="mr-2 flex-1 shadow px-6 rounded py-8"
            >
              <skeleton-list :row="3" v-if="laodingStatCA" />

              <div v-if="!laodingStatCA" class="font-ligthmb-2 pb-2">
                Chiffre d'affaire
              </div>
              <div v-if="!laodingStatCA" class="text-2xl font-semibold">
                {{ total_revenue | moneyFilter }} {{user.currency_code}}
              </div>
            </div>

            <div
              style="color: #00474f; background-color: #87e8de"
              class="mr-2 flex-1 px-6 rounded shadow py-8"
            >
              <skeleton-list :row="3" v-if="laodingStatCA" />
              <div v-if="!laodingStatCA" class="font-ligthmb-2 pb-2">
                Total achat marchandise
              </div>
              <div v-if="!laodingStatCA" class="text-2xl font-semibold">
                {{ total_bill | moneyFilter }} {{user.currency_code}}
              </div>
            </div>

            <div
              style="color: #871400; background-color: #ffbb96"
              class="mr-2 flex-1 px-6 rounded shadow py-8"
            >
              <skeleton-list :row="3" v-if="laodingStatCA" />
              <div v-if="!laodingStatCA" class="font-ligthmb-2 pb-2">
                Total des depenses
              </div>
              <div v-if="!laodingStatCA" class="text-2xl font-semibold">
                {{ total_expense | moneyFilter }} {{user.currency_code}}
              </div>
            </div>
          </div>

          <div class="mt-5 bg-white shadow p-2">
            <h4 class="font-medium mb-3">MES FACTURES RECENTES</h4>
            <Invoice />
          </div>
        </div>

        <div class="flex flex-col ml-6 w-1/3">
          <div class="bg-white shadow w-full">
            <h5 class="border-b font-medium px-3 py-2">INDICATEURS DE VENTE</h5>
            <div class="flex flex-col align-center border-b px-5 py-6">
              <skeleton-list :row="3" v-if="laodingStatOpen" />
              <div
                v-if="!laodingStatOpen"
                class="text-2xl font-semibold mb-1 text-blue"
              >
                {{ documentStatistique.total_invoice | moneyFilter }} {{user.currency_code}}
              </div>
              <div v-if="!laodingStatOpen" class="font-ligth text-gray-400">
                <span class="text-gray-400"> Mes factures en cours </span>
              </div>
            </div>
            <div class="flex mt-3 px-5 py-4">
              <div class="flex-1 flex flex-col">
                <skeleton-list :row="3" v-if="laodingStatOpen" />
                <div
                  v-if="!laodingStatOpen"
                  class="text-2xl font-semibold mb-1"
                >
                  {{ documentStatistique.total_quote | moneyFilter }} {{user.currency_code}}
                </div>
                <span v-if="!laodingStatOpen" class="text-gray-400 font-light"
                  >Mes devis en attente</span
                >
              </div>
              <div class="flex-1 flex flex-col">
                <skeleton-list :row="3" v-if="laodingStatOpen" />
                <div
                  v-if="!laodingStatOpen"
                  class="text-2xl font-semibold mb-1"
                >
                  {{ documentStatistique.total_sale_order | moneyFilter }} {{user.currency_code}}
                </div>
                <span v-if="!laodingStatOpen" class="text-gray-400 font-light"
                  >Mes bons commande en cours</span
                >
              </div>
            </div>
          </div>
          <div class="bg-white shadow w-full mt-5">
            <skeleton-list :row="3" v-if="laodingStatOpen" />
            <div v-if="!laodingStatOpen" class="flex flex-col px-5 py-3">
              <div class="text-2xl font-semibold mb-1">
                {{ total_bill_unpaid | moneyFilter }} {{user.currency_code}}
              </div>
              <div v-if="!laodingStatOpen" class="font-ligthtext-gray-400">
                <span class="text-gray-400">
                  Montant des factures d'achat
                </span>
              </div>
            </div>
          </div>
          <div class="flex flex-col shadow bg-white w-full mt-5 p-3 pb-3">
            <h5 class="border-b font-medium py-2">DETAILS DU PRODUIT</h5>
            <div class="mt-4">
              <skeleton-list :row="3" v-if="laodingStatOpen" />
              <div v-if="!laodingStatOpen" class="flex justify-between pb-4">
                <div class="text-red-500">Stock est epuisé</div>
                <div class="font-bold">{{ count_sold_out }} article(s)</div>
              </div>

              <div v-if="!laodingStatOpen" class="flex justify-between pb-4">
                <div class="text-primary">Stock est élévé</div>
                <div class="font-bold">{{ count_on_stock }} article(s)</div>
              </div>
              <!-- 
              <div class="flex justify-between pb-4">
                <div>Articles en attente de reception</div>
                <div class="font-bold">56</div>
              </div>

              <div class="flex justify-between pb-4">
                <div>Articles vendus non livrée</div>
                <div class="font-bold">56</div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";

import Invoice from "./components/invoice.vue";

import SkeletonList from "@/components/Skeleton/list";

import { fetchRapportResume, fetchStatSales } from "@/api/sales2";
import { getStatistique } from "@/api/article";
import { fetchStats, getTotalBill } from "@/api/purchase";
import { getTotalExpense } from "@/api/expense";
import { parseDate, getStartOfMonth, getEndOfMonth } from "@/utils";
import { toThousandFilter } from "@/Filters";
import {mapGetters} from "vuex";
export default {
  components: {
    Invoice,
    PageHeader,
    SkeletonList,
  },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      laodingStatOpen: false,
      laodingStatCA: false,
      laoding: false,
      total_revenue: 0,
      total_bill_unpaid: 0,
      total_item_bill_unpaid: 0,
      total_profit: 0,
      total_invoice: 0,
      count_quote: 0,
      count_order: 0,
      documentStatistique: {
        total_invoice: 0,
        total_item_invoice: 0,
        total_item_quote: 0,
        total_item_sale_order: 0,
        total_quote: 0,
        total_sale_order: 0,
      },
      total_bill: 0,
      count_on_stock: 0,
      count_sold_out: 0,
      total_expense: 0,
      listQuery: {
        start: getStartOfMonth(),
        end: getEndOfMonth(),
      },
    };
  },
    computed :{
        ...mapGetters(["user"]),
    },
  mounted() {
    this.initStatCA();
    this.initStatOpen();
  },
  methods: {
    async fetchRapportResume() {
      await fetchRapportResume(this.listQuery, "ARTICLE").then((res) => {
        this.total_revenue = res.data.total_invoice + res.data.total_ticket;
        this.total_profit = res.data.profit;
      });
    },
    initStatCA() {
      this.laodingStatCA = true;
      setTimeout(() => {
        this.getTotalExpense();
        this.fetchRapportResume();
        this.laodingStatCA = false;
      }, 1.5 * 1000);
    },
    initStatOpen() {
      this.laodingStatOpen = true;
      setTimeout(() => {
        this.fetchStatSales();
        this.fetchStatOpen();
        this.fetchStatArticle();
        this.getTotalBill();
        this.laodingStatOpen = false;
      }, 1.5 * 1000);
    },
    async fetchStatSales() {
      await fetchStatSales().then((res) => {
        this.documentStatistique = Object.assign({}, res.data);
      });
    },
    async fetchStatOpen() {
      await fetchStats()
        .then((res) => {
          this.total_bill_unpaid = res.data.total_amount;
          this.total_item_bill_unpaid = res.data.total_item;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    async fetchStatArticle() {
      await getStatistique().then((res) => {
        this.count_on_stock = res.data.count_on_stock;
        this.count_sold_out = res.data.count_sold_out;
        //this.total_bill = res.data.amount_bill_due;
      });
    },
    async getTotalBill() {
      await getTotalBill(this.listQuery).then((res) => {
        this.total_bill = res.data.total;
      });
    },
    async getTotalExpense() {
      await getTotalExpense(this.listQuery).then((res) => {
        this.total_expense = res.data.total;
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
