<template>
  <div class="flex flex-col font-Inter">
    <div class="flex flex-col bg-white mb-5">
      <div class="flex flex-col customer-white">
        <div v-if="listLoading">
          <skeleton-list :row="10" />
        </div>
        <div else>
          <div v-for="(item, index) in items" :key="index">
            <router-link
              class="flex justify-between items-center p-2 invoice"
              :style="{
                borderLeft: `6px solid ${colors[index]}`,
              }"
              :to="`sales/invoice/` + item._id"
            >
              <div class="flex-1">
                <div class="font-medium">N°: {{ item.number }}</div>
                <div class="text-blue font-semibold">
                  {{ item.contact_name }}
                </div>
                <div class="flex flex-row">
                  <div class="mr-2 text-gray-400 font-light">
                    {{ item.created_at }}
                  </div>
                </div>
              </div>
              <div class="flex flex-col justify-end items-end">
                <div class="font-semibold">
                  {{ item.total | moneyFilter }} {{user.currency_code}}
                </div>
                <Status :name="item.status" />
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAllDocument } from "@/api/sales2";
import { toThousandFilter } from "@/Filters";
import { parseDate } from "@/utils";
import Status from "@/components/Status";
import SkeletonList from "@/components/Skeleton/list";
import {mapGetters} from "vuex";
export default {
  name: "Article",
  components: { Status, SkeletonList },
  filters: {
    filterTime: parseDate,
    moneyFilter: toThousandFilter,
  },
  data() {
    return {
      invoices: [],
      listLoading: false,
      labelPosition: "top",
      items: [],
      colors: ["#6956E5", "#009688", "#ef4a53", "#151515", "#c73080"],
      exportLoading: false,
      listQuery: {
        total: 0,
        page: 1,
        size: 5,
        sort: "desc",
        sortDir: "createdAt",
        start: undefined,
        deadlineDate: undefined,
        end: undefined,
        isUnpaid: undefined,
        contactId: undefined,
        invoiceNo: undefined,
        status: undefined,
        type: "INVOICE",
      },
    };
  },
    computed :{
        ...mapGetters(["user"]),
    },
  mounted() {
    this.fetchInvoices();
  },
  methods: {
    async fetchInvoices() {
      this.listLoading = true;
      await getAllDocument(this.listQuery, this.listQuery.type)
        .then((res) => {
          setTimeout(() => {
            this.items = res.data.items;

            this.listQuery.total = parseInt(res.data.total_items);
            this.listQuery.page = parseInt(res.data.current_page) + 1;
            this.listLoading = false;
          }, 1.5 * 1000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.invoice {
  border-bottom: 1px dashed #dcdfe6;
  margin-bottom: 4px;
  cursor: pointer;
}
</style>
